import { DEFAULT_DATA } from './misc';


export const responses = {
    list: {
        data: {
            ...DEFAULT_DATA,
            items: {
                'imunify-ui': '7.11.1-1',
                'imunify-antivirus': '7.12.1-1',
                'imunify-core': '7.12.0-1',
                'imunify360-firewall': '7.12.1-1'
            }

        },
    },
};

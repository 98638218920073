import { DEFAULT_DATA } from './misc';

export const responses = {
    load: {
        data: {
            ...DEFAULT_DATA,
            data: {
                chunk: 'some file content\nanother line\nanother one',
                limit: 102400,
                offset: 0,
                size: 170,
                eof: false,
            },
        },
    },
};

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from 'app/services/auth';
import { Package } from '@imunify360-api/license';
import { APP_BASE_HREF, LocationStrategy } from '@angular/common';
import { ZonedHashLocationStrategy } from './zoned-hash-location-strategy';

export const otherRootRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [AuthService],
        loadChildren: () => import('app/empty-route/empty-route.module').then(m => m.EmptyRouteModule),
    },
    {
        path: Package.imunify360,
        canLoad: [AuthService],
        children: [
            {
                path: 'admin',
                loadChildren: () => import('app/core/admin.module').then(m => m.AdminModule),
                canLoad: [AuthService],
            },
            {
                path: 'client',
                loadChildren: () => import('app/core/client.module').then(m => m.ClientModule),
                canLoad: [AuthService],
            },
        ],
    },
    {
        path: 'installation',
        loadChildren: () => import('app/components/installation/installation.module')
            .then(m => m.InstallationModule),
    },
    {
        path: Package.imunifyAV,
        canLoad: [AuthService],
        loadChildren: () => import('app/components/av/av.module').then(m => m.AvModule),
    },
    {
        path: 'login',
        loadChildren: () => import('app/components/login/login.module').then(m => m.LoginModule),
        canLoad: [AuthService],
    },
    {
        path: 'showcase',
        loadChildren: () => import('app/components/showcase/showcase.module')
            .then(m => m.ShowcaseModule),
    },
    {
        path: 'standalone-warning',
        loadChildren: () => import('app/standalone-warning/standalone-warning.module')
        .then(m => m.StandaloneWarningModule),
    },
    {
        path: '**',
        loadChildren: () => import('app/empty-route/empty-route.module').then(m => m.EmptyRouteModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            otherRootRoutes,
            { useHash: true, relativeLinkResolution: 'legacy', onSameUrlNavigation: 'ignore' },
        ),
    ],
    exports: [RouterModule],
    providers: [
        { provide: LocationStrategy, useClass: ZonedHashLocationStrategy },
        { provide: APP_BASE_HREF, useValue: '/' },
    ],
})
export class OtherRootRoutingModule { }

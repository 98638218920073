import { setPublicPath } from 'systemjs-webpack-interop';

/* Make sure your import map has the name of your module in it. Example:
{
  "imports": {
    "@org-name/my-module": "https://example.com/dist/js/main.js"
  }
}
 */

// __webpack_public_path__ will be set to https://example.com/dist/js/
setPublicPath('@imunify/other-root');


import { enableProdMode, NgZone } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { singleSpaAngular } from 'single-spa-angular';

import { OtherRootModule } from 'app/core/other-root.module';
import { environment } from 'environments/environment';
import { loadTestInterceptor } from 'app/core/misc';
import { wrapLifecycleMethod } from './common.single-spa';

if (environment.production) {
    try {
        // The `enableProdMode` will throw an error if it's called multiple times,
        // but it may be called multiple times when dependencies are shared.
        enableProdMode();
    } catch {
        // Nothing to do here.
    }
}

if (TEST) {
    loadTestInterceptor();
}

const lifecycles = singleSpaAngular({
    bootstrapFunction: () => platformBrowserDynamic().bootstrapModule(OtherRootModule),
    template: '<div class="i360-app i360-other"><app /></div>',
    // Do not provide these. If provided, Single-Spa tries to "fix" angular routing to let multiple
    // apps coexist. However, their "fix" brings more issues than benefits.
    // For the same reason, we don't need `getSingleSpaExtraProviders` above.
    // Router,
    // NavigationStart,
    NgZone,
});
export const bootstrap = wrapLifecycleMethod(lifecycles.bootstrap, '@imunify/other-root');
export const mount = wrapLifecycleMethod(lifecycles.mount, '@imunify/other-root');
export const unmount = wrapLifecycleMethod(lifecycles.unmount, '@imunify/other-root');

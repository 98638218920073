import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { hideAppLoader } from 'app/utils/loader/loader.utils';

@Component({
    selector: 'app',
    template: '<router-outlet></router-outlet>',
})
export class RootComponent {
    constructor(renderer: Renderer2, router: Router) {
        hideAppLoader(renderer, router);
    }
}

import '../styles/global/app.style.scss';

import { AppState } from 'app/core/app.service';
import { AuthState } from 'app/services/auth-state';
import { LicenseState } from 'app/services/license-state';
import { RequestsState } from 'app/services/requests-state';
import {
    AppState as SharedAppState,
    AuthState as SharedAuthState,
    LicenseState as SharedLicenseState,
    RequestsState as SharedRequestsState,
    MyImunifyState as SharedMyImunifyState,
    FirewallState as SharedFirewallState,
    getSharedInjector,
} from '@imunify/nav-root';

import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from 'app/utils/translate/TranslateHttpLoader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RootComponent } from 'app/components/root/root.component';
import { I360TranslateModule } from 'app/utils/translate/translate.module';
import { OtherRootRoutingModule } from 'app/core/other-root-routing.module';
import { AuthService } from 'app/services/auth';
import { getWindow, WindowToken } from 'app/core/window';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DomSanitizer } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RootErrorHandler } from 'app/services/sentry';
import { I360OverlayContainer } from 'app/utils/overlay-container';
import { UrlInterceptor } from 'app/interceptors/url.interceptor';
import { JsonHeaderInterceptor } from 'app/interceptors/json-header.interceptor';
import { Panel } from 'app/utils/panel';
import { I360NoContentModule } from 'app/components/no-content/no-content.module';
import { MatIconRegistry } from '@angular/material/icon';
import { applyCsrfStrategy, enableSvgIcons } from 'app/core/misc';
import { SentryInterceptor } from 'app/interceptors/sentry.interceptor';
import { ImunifyStatusInterceptor } from 'app/interceptors/imunify-status.interceptor';
import { ConfigRef } from 'app/core/config-ref';
import { LicenseInterceptor } from '../interceptors/license.interceptor';
import { LicenseService } from 'app/services/license';
import { UnsavedConfigRouteGuard } from './unsaved-route-guard';
import { MyImunifyService } from 'app/services/my-imunify';
import { AuthCloudService } from 'app/services/auth-cloud';
import { MyImunifyState } from 'app/services/my-imunify-state';
import { FirewallState } from 'app/components/lists/firewall-state.service';


@NgModule({
    bootstrap: [ RootComponent ],
    declarations: [
        RootComponent,
    ],
    imports: [
        OtherRootRoutingModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateHttpLoader,
            },
        }),
        I360TranslateModule.forRoot(),
        HttpClientModule,
        I360NoContentModule,
    ],
    providers: [
        { provide: AppState, useFactory: () => getSharedInjector().get(SharedAppState) },
        { provide: AuthState, useFactory: () => getSharedInjector().get(SharedAuthState) },
        { provide: LicenseState, useFactory: () => getSharedInjector().get(SharedLicenseState) },
        { provide: RequestsState, useFactory: () => getSharedInjector().get(SharedRequestsState) },
        { provide: MyImunifyState, useFactory: () => getSharedInjector().get(SharedMyImunifyState) },
        { provide: FirewallState, useFactory: () => getSharedInjector().get(SharedFirewallState) },

        AuthService,
        LicenseService,
        ConfigRef,
        UnsavedConfigRouteGuard,
        Panel,
        MyImunifyService,
        AuthCloudService,
        { provide: WindowToken, useFactory: getWindow },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'never' } },
        { provide: OverlayContainer, useClass: I360OverlayContainer},
        { provide: HTTP_INTERCEPTORS, useExisting: AuthService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: JsonHeaderInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: SentryInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: ImunifyStatusInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: LicenseInterceptor, multi: true},
        RootErrorHandler,
        { provide: ErrorHandler, useExisting: RootErrorHandler},
    ],
})
export class OtherRootModule {
    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        panel: Panel,
    ) {
        if (panel.isPlesk) {
            applyCsrfStrategy();
        }
        enableSvgIcons(iconRegistry, sanitizer);
    }
}

import { AdvicesListsType } from './advisor';

export enum ChartName {
    num_of_alerts = 'num_of_alerts',
    num_of_mrs = 'num_of_mrs',
    num_of_cleanups = 'num_of_cleanups',
    captcha_blocked = 'captcha_blocked',
    waf_alerts = 'waf_alerts',
    bruteforce_attacks = 'bruteforce_attacks',
    ossec_alerts = 'ossec_alerts',
    denied_bot_attacks = 'denied_bot_attacks',
    spam_ham = 'spam_ham',
    ham = 'ham',
    spam = 'spam',
}

export type ServerResponse<E> = {
    result: E;
};

export type DashboardServerIdsResponseInServer = ServerResponse<Array<{
    server_id: string,
    ip: string | null,
    nodename: string | null;
}>>;

export type AdvicesListsResponseInServer = ServerResponse<AdvicesListsType>;

export interface DashboardAddServerResponseInServer extends DashboardServerIdsResponseInServer {
    errors: Array<{
        server_id: string,
        msg: string,
    }>;
}

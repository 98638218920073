import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanDeactivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { ConfigRef } from 'app/core/config-ref';
import { Observable } from 'rxjs';


@Injectable()
export class UnsavedConfigRouteGuard implements CanDeactivate<any> {
    constructor(
        public configRef: ConfigRef,
    ) {}

    canDeactivate(
        component: any,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (typeof component.confirmReload !== 'undefined' && !component.confirmReload()) {
            return true;
        }
        return this.configRef.config?.formConfirmed();
    }
}

import { Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take, timeout, catchError, of } from 'rxjs';

export function hideAppLoader(renderer: Renderer2, router: Router): void {
    router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
        timeout(2000), // timeout after 2 seconds
        catchError(() => of(null))
    ).subscribe(() => {
        // need to use document.querySelector, because elements are outside of Angular scope
        const container = document.querySelector<HTMLElement>('.i360-app__container');
        const loader = document.querySelector<HTMLElement>('.i360-app__loader');

        if (container && loader && window.getComputedStyle(container).display === 'none') {
            renderer.setStyle(loader, 'display', 'none');
            renderer.setStyle(container, 'display', 'flex');
        }
    });
}
